import React from 'react';
import { Drawer, Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import InfiniteScrollTable from '../../../../components/tables/InfiniteScrollTable';

import drawerTransition, {
  DrawerVisibleProps,
} from '../../../../components/drawer-transition';
import useCopyWithNotification from '../../../../hooks/use-copy-with-notification';
import { OaasMid } from '../../types';
import { DOUBLE_MINUS } from '../../../../utils/special-character';
import { icons } from '../../../../utils/schemes';
import { Scheme, StyledPre } from '../styled';

export interface MidsDrawerProps extends DrawerVisibleProps {
  offerMids: OaasMid[];
  onClose: () => void;
}

function MidsDrawer({
  offerMids,
  visible,
  afterVisibleChange,
  onClose,
}: MidsDrawerProps) {
  const { t } = useTranslation(['common', 'offers']);
  const copyWithNotification = useCopyWithNotification();
  const copyNetworkIdentifiersWithNotif = useCopyWithNotification(
    t('offers:fields.mids.copyNetworkIdentifiers.success'),
  );

  const midsToRow = offerMids.map((offerMid: OaasMid) => ({
    contents: [
      offerMid.scheme && offerMid.networkIdentifiers ? (
        <>
          <Scheme key={offerMid.scheme}>
            <img
              alt={offerMid.scheme}
              className={offerMid.scheme}
              style={{ top: 0 }}
              src={icons[offerMid.scheme]}
              role="presentation"
            />
          </Scheme>
          <StyledPre>{JSON.stringify(offerMid.networkIdentifiers)}</StyledPre>
        </>
      ) : (
        DOUBLE_MINUS
      ),
      moment(offerMid.created).format('MMM DD, YYYY'),
    ],
    actions: [
      {
        label: t('common:actions.copyId'),
        callback: () => copyWithNotification(offerMid.id),
      },
      ...(offerMid.networkIdentifiers
        ? [
            {
              label: t('offers:fields.mids.copyNetworkIdentifiers.label'),
              callback: () =>
                copyNetworkIdentifiersWithNotif(
                  JSON.stringify(offerMid.networkIdentifiers),
                ),
            },
          ]
        : []),
    ],
  }));

  return (
    <Drawer
      data-testid="offer-mids-drawer"
      closable={false}
      width="45%"
      visible={visible}
      title={t('offers:fields.mids.label')}
      afterVisibleChange={afterVisibleChange}
      onClose={onClose}
      footer={
        <Row>
          <Button type="primary" onClick={onClose} size="small">
            {t('common:confirm.done')}
          </Button>
        </Row>
      }
    >
      <InfiniteScrollTable
        columns={[
          { heading: t('offers:fields.mids.columns.networkIdentifiers') },
          {
            heading: t('offers:fields.mids.columns.created'),
            size: 0.25,
          },
        ]}
        minWidth={100}
        rows={midsToRow}
      />
    </Drawer>
  );
}

export default drawerTransition(MidsDrawer);
