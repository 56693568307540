import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { InfoCircleFilled } from '@ant-design/icons';
import { OfferTransactionSource } from '@fidel.uk/types/lib/offer';
import { Offer, OfferType } from '../../../store/offers/offers-model';
import { OaasMid, OnSelectProps, SelectedUniqueOfferPayload } from '../types';
import FieldsList from './components/FieldsList';
import { DetailsContainer } from './styled';
import Detail from '../../../components/detail';
import { isUniqueOffer, transformOfferToDetailTitle } from '../utils';
import { Action } from '../../../components/actions-menu';
import { colors } from '../../../theme';
import AddUniqueOffer from '../add-unique-offer';
import PublisherProfile from '../publisher-profile';
import { DetailCardFooter } from '../../../components/detail/styled/detail';
import OfferLabels from '../components/OfferLabels';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getAllMids } from '../../../store/offers/offers-actions';

export interface OfferDetailsProps {
  offer: Offer;
  onSelect: OnSelectProps;
  actions: Action[];
  onClear: () => void;
}

export default function OfferDetails({
  offer,
  onSelect,
  actions,
  onClear,
}: OfferDetailsProps) {
  const dispatch = useAppDispatch();
  const offerId = offer.id as string;

  const { mids } = useAppSelector(state => ({
    mids: state.offers.mids,
  }));

  const offerMids: OaasMid[] =
    offerId && mids[offerId] && !!mids[offerId].items
      ? (mids[offerId].items as OaasMid[])
      : [];

  const midsLoading = mids[offerId]?.loading;

  const { t } = useTranslation('offers');
  const ref = useRef<HTMLDivElement>(null);
  const { type: offerType } = useParams<{ type: OfferType }>();

  const { funded, locationsTotal } = offer;

  const isMarketplaceOffer = isUniqueOffer(offer);

  const isOaas = offer.transactionSource === OfferTransactionSource.OAAS;

  const [showAddUniqueOffer, setShowAddUniqueOffer] = useState(false);
  const [selectedUniqueOfferPayload, setSelectedUniqueOfferPayload] =
    useState<SelectedUniqueOfferPayload>();

  useEffect(() => {
    ref.current?.scrollTo?.({
      top: 0,
    });
  }, [offer]);

  useEffect(() => {
    // get offer mids if not fetched already
    if (offerType !== 'marketplace' && isOaas && offer.id && !mids[offer.id]) {
      dispatch(getAllMids(offer.id));
    }
  }, [dispatch, isOaas, offerType, offer.id, mids]);

  return (
    <>
      <Detail
        actions={actions}
        clear={onClear}
        emptyMessage={t('detail.empty')}
        title={{
          ...transformOfferToDetailTitle(offer),
          labels: <OfferLabels offer={offer} />,
        }}
        ref={ref}
      >
        <DetailsContainer data-testid="offer-detail-view">
          {!isOaas && locationsTotal === 0 && (
            <Alert
              showIcon
              icon={<InfoCircleFilled style={{ color: colors.fidelBlue600 }} />}
              message={
                !isMarketplaceOffer
                  ? t('detail.alerts.missingLocation')
                  : t('detail.alerts.missingLocationUniqueOffer')
              }
              type="info"
              style={{ marginTop: 0 }}
            />
          )}

          {!funded && !isMarketplaceOffer && (
            <Alert
              showIcon
              icon={<InfoCircleFilled style={{ color: colors.fidelBlue600 }} />}
              message={t('detail.alerts.notFunded')}
              type="info"
              style={{ marginTop: 0 }}
            />
          )}

          <FieldsList
            offer={offer}
            offerMids={offerMids}
            midsLoading={midsLoading}
          />

          {offerType === 'marketplace' && (
            <DetailCardFooter data-onboarding-target="marketplace-add-to-program">
              <Button
                type="primary"
                block
                onClick={() => {
                  onSelect.set.offer(offer);
                  setShowAddUniqueOffer(true);
                }}
              >
                {t(
                  `actions.${
                    offer.requiresApproval
                      ? 'applyForApproval'
                      : 'addToPrograms'
                  }`,
                )}
              </Button>
            </DetailCardFooter>
          )}
        </DetailsContainer>
      </Detail>

      <AddUniqueOffer
        uniqueOffer={offer}
        visible={showAddUniqueOffer}
        onClose={() => setShowAddUniqueOffer(false)}
        setSelectedUniqueOfferPayload={setSelectedUniqueOfferPayload}
      />

      {selectedUniqueOfferPayload && (
        <PublisherProfile
          selectedUniqueOfferPayload={selectedUniqueOfferPayload}
        />
      )}
    </>
  );
}
