import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Input } from 'antd';
import { transformOfferToDetailTitle } from '../../utils';
import { Offer } from '../../../../store/offers/offers-model';
import DetailTitle from '../../../../components/detail/components/DetailTitle';
import { useAppSelector } from '../../../../store/hooks';
import { Brand } from '../../../../store/brands/brands-model';
import Form from '../../../../components/form';
import { Select } from '../../../../components/select';
import { FormButton } from '../../../../components/buttons';
import { icons } from '../../../../utils/schemes';
import { Divider } from '../../../../components/side-bar/styled';

const getLabelIcon = (scheme: string) => (
  <img alt={scheme} src={icons[scheme]} role="presentation" />
);
const options = [
  {
    label: <span>{getLabelIcon('visa')} (CAID/BIN)</span>,
    value: 'visa-cb',
  },
  {
    label: <span>{getLabelIcon('visa')} (VMID/VSID)</span>,
    value: 'visa-vv',
  },
  {
    label: getLabelIcon('mastercard'),
    value: 'mastercard',
  },
  {
    label: getLabelIcon('amex'),
    value: 'amex',
  },
];

const inputsPerScheme: Record<string, string[]> = {
  'visa-cb': ['caid', 'bin'],
  'visa-vv': ['vmid', 'vsid'],
  mastercard: ['merchantId', 'locationId'],
  amex: ['seNumber'],
};

export default function MidsForm({ offer }: { offer: Partial<Offer> }) {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'mids',
  });
  const { brandId } = offer;

  const { brands } = useAppSelector(
    ({ programs, offers, locations, ...state }) => ({
      brands: state.brands.brands,
    }),
  );

  const brand = useMemo<Brand>(
    () => brands.find(({ id }) => id === brandId) ?? ({} as Brand),
    [brandId, brands],
  );

  const { t } = useTranslation('offers');

  // if offer already exists (update operation) dont show anything
  if (offer.id) {
    return <></>;
  }

  return (
    <>
      <DetailTitle
        {...transformOfferToDetailTitle({
          ...offer,
          brandLogoURL: brand.logoURL,
          brandName: brand.name,
        })}
      />

      <div style={{ width: 'flex', marginTop: '1rem' }}>
        <FormButton
          size="small"
          block
          style={{ marginBottom: '1rem' }}
          onClick={() => append({})}
        >
          {t('createUpdate.mids.add')}
        </FormButton>

        {fields.map((field, index) => {
          const networkIdentifierScheme = getValues(
            `mids[${index}].scheme`,
          ) as string;

          return (
            <div key={field.id} style={{ marginBottom: '20px' }}>
              <Form.ItemController
                errors={errors}
                controller={{
                  name: `mids[${index}].scheme`,
                  render: ({ field: fld }) => (
                    <Select
                      {...fld}
                      data-testid="mid-scheme"
                      placeholder={t('createUpdate.mids.midScheme')}
                      dropdownMatchSelectWidth={false}
                    >
                      {options.map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  ),
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />

              <div style={{ display: 'flex', gap: '1rem' }}>
                {networkIdentifierScheme &&
                  inputsPerScheme[networkIdentifierScheme].map(
                    (networkIdentifier: string, idx) => (
                      <Form.ItemController
                        key={`${networkIdentifier}-${idx}`}
                        errors={errors}
                        label={t(
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          `createUpdate.mids.${networkIdentifierScheme}.${networkIdentifier}`,
                        )}
                        controller={{
                          name: `mids[${index}].networkIdentifiers.${networkIdentifier}`,
                          render: ({ field: { onChange, value, ...rest } }) => (
                            <Input
                              {...rest}
                              defaultValue={value}
                              onChange={({ target }) =>
                                onChange(`${target.value}`)
                              }
                            />
                          ),
                          control,
                          rules: {
                            required: true,
                          },
                        }}
                      />
                    ),
                  )}
              </div>

              <FormButton size="small" block onClick={() => remove(index)}>
                {t('createUpdate.mids.remove')}
              </FormButton>

              <Divider />
            </div>
          );
        })}
      </div>
    </>
  );
}
