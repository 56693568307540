import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Offer, transformToOffer } from '../offers-model';
import { actionToError } from '../../utils/transform';

type LocationsStatus = { loading: boolean; items?: any[] };

export interface CurrentOfferState {
  createdOrUpdated: boolean;
  loading: boolean;
  locations: Record<string, { loading: boolean; items?: any[]; error?: any }>;
  locationsStatus?: {
    linked?: LocationsStatus;
    unlinked?: LocationsStatus;
  };
  paymentCompleted: boolean;
  saving: boolean;
  error?: any;
  offer?: Offer;
  allLocationsLinkingStatus: {
    loading: boolean;
    success?: boolean;
  };
  addedUniqueOffers: { items: string[]; new?: string };
  uniqueLocations: Record<
    string,
    { loading: boolean; items?: any[]; error?: any }
  >;
}

export const initialState: CurrentOfferState = {
  createdOrUpdated: false,
  loading: false,
  locations: {},
  paymentCompleted: false,
  saving: false,
  allLocationsLinkingStatus: {
    loading: false,
  },
  addedUniqueOffers: {
    items: [],
    new: undefined,
  },
  uniqueLocations: {},
};

const currentOfferSlice = createSlice({
  name: 'currentOffer',
  initialState,
  reducers: {
    clear: () => initialState,
    clearError(state) {
      state.error = undefined;
    },
    clearSubState: (
      state,
      { payload }: PayloadAction<keyof CurrentOfferState>,
    ) => {
      state[payload] = initialState[payload];
    },
    createOffer(state) {
      state.loading = true;
      state.createdOrUpdated = false;
      state.error = undefined;
    },
    createOfferSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.createdOrUpdated = true;
      state.offer = transformToOffer(action.payload.items[0]);
    },
    createOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getOffer(state) {
      state.loading = true;
      state.error = undefined;
    },
    getOfferSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.offer = transformToOffer(action.payload.items[0]);
    },
    getOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getAllLocations(state, action: PayloadAction<any>) {
      state.locations = {
        ...state.locations,
        [action.payload]: { loading: true },
      };
    },
    getAllLocationsSuccess(state, action: PayloadAction<any>) {
      state.locations = {
        ...state.locations,
        [action.payload.offerId]: {
          loading: false,
          items: action.payload.items,
        },
      };
    },
    getAllLocationsError(state, action: PayloadAction<any>) {
      state.locations = {
        ...state.locations,
        [action.payload.offerId]: {
          loading: false,
          error: actionToError(action),
        },
      };
    },
    linkCardToOffer(state) {
      state.loading = true;
      state.error = undefined;
    },
    linkCardToOfferSuccess(state) {
      state.loading = false;
    },
    linkCardToOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    linkLocation(state) {
      state.loading = true;
      state.error = undefined;
    },
    linkLocationSuccess(state) {
      state.loading = false;
    },
    linkLocationError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    linkMids(state) {
      state.loading = true;
    },
    linkMidsSuccess(state) {
      state.loading = false;
    },
    linkLocations(state) {
      state.loading = true;
      state.locationsStatus = { linked: { loading: true, items: [] } };
    },
    linkLocationsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.locationsStatus = {
        linked: { loading: false, items: action.payload },
      };
    },
    unlinkLocation(state) {
      state.error = undefined;
      state.loading = true;
    },
    unlinkLocationSuccess(state) {
      state.loading = false;
    },
    unlinkLocationError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    unlinkLocations(state) {
      state.loading = true;
      state.locationsStatus = { unlinked: { loading: true, items: [] } };
    },
    unlinkLocationsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.locationsStatus = {
        unlinked: { loading: false, items: action.payload },
      };
    },
    updateOffer(state) {
      state.loading = true;
      state.createdOrUpdated = false;
      state.error = undefined;
    },
    updateOfferSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.createdOrUpdated = true;
      state.offer = transformToOffer(action.payload.items[0]);
    },
    updateOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    linkAllProgramsLocationsToOffer(state) {
      state.allLocationsLinkingStatus.loading = true;
    },
    linkAllProgramsLocationsToOfferSuccess(state) {
      state.allLocationsLinkingStatus.loading = false;
      state.allLocationsLinkingStatus.success = true;
    },
    linkAllProgramsLocationsToOfferError(state) {
      state.allLocationsLinkingStatus.loading = false;
      state.allLocationsLinkingStatus.success = false;
    },
    addUniqueOffer(state) {
      state.loading = true;
      state.error = undefined;
    },
    addUniqueOfferSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.addedUniqueOffers = {
        items: [...state.addedUniqueOffers.items, action.payload],
        new: action.payload,
      };
      state.error = false;
    },
    addUniqueOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    resetNewlyAddedUniqueOffer(state) {
      state.addedUniqueOffers.new = undefined;
    },
    promoteMarketplaceOaasOffer(state) {
      state.loading = true;
      state.error = undefined;
    },
    promoteMarketplaceOaasOfferSuccess(state) {
      state.loading = false;
    },
    promoteMarketplaceOaasOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getAllUniqueLocations(state, action: PayloadAction<any>) {
      state.uniqueLocations = {
        ...state.uniqueLocations,
        [action.payload]: { loading: true },
      };
    },
    getAllUniqueLocationsSuccess(state, action: PayloadAction<any>) {
      state.uniqueLocations = {
        ...state.uniqueLocations,
        [action.payload.uniqueOfferId]: {
          loading: false,
          items: action.payload.items,
        },
      };
    },
    getAllUniqueLocationsError(state, action: PayloadAction<any>) {
      state.uniqueLocations = {
        ...state.uniqueLocations,
        [action.payload.uniqueOfferId]: {
          loading: false,
          error: actionToError(action),
        },
      };
    },
  },
});

export default currentOfferSlice.reducer;
export const { actions } = currentOfferSlice;
