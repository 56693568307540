import styled from 'styled-components';
import { colors } from '../../../../theme';

export const DetailsContainer = styled.div`
  .ant-alert {
    margin-bottom: 8px;
  }

  .ant-descriptions-item-container {
    flex-flow: column;
  }

  .ant-descriptions-item {
    padding-bottom: 0;

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      display: block;
    }

    .ant-descriptions-item-label {
      color: ${colors.grey4};
      font-size: 12px;
      padding-bottom: 4px;
    }

    .ant-descriptions-item-content {
      padding-bottom: 16px;
    }
  }
`;

export const Scheme = styled.span`
  border: 1px solid ${colors.lightgrey2};
  border-radius: 4px;
  display: inline-flex;
  height: 26px;
  justify-content: center;
  margin-right: 8px;
  margin-top: 2px;
  padding: 6px;
  position: relative;
  top: 4px;
  width: 42px;

  .amex {
    width: 34px;
  }

  .mastercard {
    width: 21px;
  }

  .visa {
    width: 29px;
  }
`;

export const StyledSpan = styled.span`
  display: inline-flex;
  & > svg {
    margin-left: 3px;
  }
`;

export const StyledPre = styled.pre`
  background-color: ${colors.grey2};
  padding: 5px;
  display: inline;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  color: ${colors.black};
`;
