import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Offer, transformToOffer } from './offers-model';
import { actionToError } from '../utils/transform';
import { OaasMid, OfferProviderType } from '../../modules/offers/types';

export interface Offers {
  [index: string]: any;
  all?: Offer[];
}

export interface OfferState {
  bootstrapped: boolean;
  error: any;
  loading: boolean;
  offers: Offers;
  contentProviders: OfferProviderType[];
  reportingProviders: OfferProviderType[];
  counts: Record<string, { loading: boolean; count?: number; error?: true }>;
  mids: Record<string, { loading: boolean; items?: OaasMid[]; error?: true }>;
  programsWithLinkedLocations: Record<string, string[]>;
}

export const initialState: OfferState = {
  bootstrapped: false,
  error: null,
  loading: false,
  offers: {
    all: [],
  },
  contentProviders: [],
  reportingProviders: [],
  counts: {},
  mids: {},
  programsWithLinkedLocations: {},
};

const offerSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    clear: () => initialState,
    getAllOffers(state) {
      state.loading = true;
    },
    getAllOffersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.bootstrapped = true;
      state.offers.all = action.payload.map(transformToOffer);
    },
    getAllOffersError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getContentProviders(state) {
      state.loading = true;
    },
    getContentProvidersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.contentProviders = action.payload.contentProviders;
    },
    getContentProvidersError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getReportingProviders(state) {
      state.loading = true;
    },
    getReportingProvidersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.reportingProviders = action.payload.reportingProviders;
    },
    getReportingProvidersError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getOfferLocationsCount(state, action: PayloadAction<{ offerId: string }>) {
      const { offerId } = action.payload;
      state.counts[offerId] = {
        loading: true,
      };
    },
    getOfferLocationsCountSuccess(
      state,
      action: PayloadAction<{ count: number; offerId: string }>,
    ) {
      const { offerId, count } = action.payload;

      state.counts[offerId] = {
        count,
        loading: false,
      };
    },
    getOfferLocationsCountError(
      state,
      action: PayloadAction<{ offerId: string }>,
    ) {
      const { offerId } = action.payload;
      state.counts[offerId] = {
        loading: false,
        error: true,
      };
    },
    getAllMids(state, action: PayloadAction<{ offerId: string }>) {
      const { offerId } = action.payload;
      state.mids[offerId] = {
        loading: true,
      };
    },
    getAllMidsSuccess(
      state,
      action: PayloadAction<{ offerId: string; items: any[] }>,
    ) {
      const { offerId, items } = action.payload;

      state.mids[offerId] = {
        loading: false,
        items,
      };
    },
    getAllMidsError(state, action: PayloadAction<{ offerId: string }>) {
      const { offerId } = action.payload;

      state.mids[offerId] = {
        loading: false,
        error: true,
      };
    },
    getProgramsWithLinkedLocationsSuccess(
      state,
      action: PayloadAction<{
        offerId: string;
        programsWithLinkedLocations: string[];
      }>,
    ) {
      const { offerId, programsWithLinkedLocations } = action.payload;
      state.programsWithLinkedLocations[offerId] = programsWithLinkedLocations;
    },
    getProgramsWithLinkedLocationsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
  },
});

export default offerSlice.reducer;
export const { actions } = offerSlice;
