import OfferApiService from './offers-api-service';
import { actions } from './offers-reducer';
import { Thunk } from '../types';

const offerService: OfferApiService = new OfferApiService();

export const { clear } = actions;

export const getAllOffers = (): Thunk => async dispatch => {
  dispatch(actions.getAllOffers());

  try {
    const data = await offerService.getAllOffers();
    dispatch(actions.getAllOffersSuccess(data));
  } catch (error) {
    dispatch(actions.getAllOffersError({ error }));
  }
};

export const getContentProviders = (): Thunk => async dispatch => {
  dispatch(actions.getContentProviders());

  try {
    const response = await offerService.getContentProviders();

    dispatch(
      actions.getContentProvidersSuccess({
        contentProviders: response.data.items,
      }),
    );
  } catch (error) {
    dispatch(actions.getContentProvidersError({ error }));
  }
};

export const getReportingProviders = (): Thunk => async dispatch => {
  dispatch(actions.getReportingProviders());

  try {
    const response = await offerService.getReportingProviders();

    dispatch(
      actions.getReportingProvidersSuccess({
        reportingProviders: response.data.items,
      }),
    );
  } catch (error) {
    dispatch(actions.getReportingProvidersError({ error }));
  }
};

export const getOfferLocationsCount =
  (offerId: string): Thunk =>
  async dispatch => {
    dispatch(actions.getOfferLocationsCount({ offerId }));

    try {
      const count = await offerService.getOfferLocationsCount(offerId);
      dispatch(actions.getOfferLocationsCountSuccess({ offerId, count }));
    } catch (error) {
      dispatch(actions.getOfferLocationsCountError({ offerId }));
    }
  };

export const getAllMids =
  (offerId: string): Thunk =>
  async dispatch => {
    dispatch(actions.getAllMids({ offerId }));

    try {
      const response = await offerService.getAllOfferMids(offerId);
      dispatch(actions.getAllMidsSuccess({ offerId, items: response }));
    } catch (error) {
      dispatch(actions.getAllMidsError({ offerId }));
    }
  };

export const getProgramsWithLinkedLocations =
  (offerId: string, programIds: string[]): Thunk =>
  async dispatch => {
    try {
      const offerLinkedProgramsPromises =
        await offerService.getProgramsWithLinkedLocations(offerId, programIds);

      const offerLinkedPrograms = await Promise.all(
        offerLinkedProgramsPromises,
      );

      const programsWithLinkedLocations: string[] = [];
      offerLinkedPrograms.forEach((result, index) => {
        if (result.data.count > 0)
          programsWithLinkedLocations.push(programIds[index]);
      });

      dispatch(
        actions.getProgramsWithLinkedLocationsSuccess({
          offerId,
          programsWithLinkedLocations,
        }),
      );
    } catch (error) {
      dispatch(actions.getProgramsWithLinkedLocationsError({ error }));
    }
  };
