import { Dispatch, SetStateAction } from 'react';
import { OfferTransactionSource } from '@fidel.uk/types/lib/offer';
import { WeekDay, CountryCode } from '../../types';
import {
  Offer,
  OfferSupplierFields,
  OfferCardActivation,
} from '../../store/offers/offers-model';

export type OfferProps = Offer | null;

export enum OfferActions {
  CREATE = 'create',
  EDIT = 'edit',
  ADD_UNIQUE = 'add_unique',
}

export type OfferAction = OfferActions | null;

export interface OnSelectProps {
  get: {
    action: OfferAction;
    offer: OfferProps;
  };
  set: {
    action: Dispatch<SetStateAction<OfferAction>>;
    offer: Dispatch<SetStateAction<OfferProps>>;
    offerTransactionSource: Dispatch<
      SetStateAction<OfferTransactionSource | undefined>
    >;
  };
}

export type OfferQualifiedTransactionsLimit = {
  [K in keyof OfferCardActivation]-?: OfferCardActivation[K];
}['qualifiedTransactionsLimit'];

export interface AddUniqueOfferOptionals {
  activation?: OfferCardActivation;
}
export interface SelectedUniqueOfferPayload {
  id: string;
  programIds: string[];
  supplierFields?: OfferSupplierFields;
  cardActivationLimit?: OfferQualifiedTransactionsLimit;
  transactionSource?: OfferTransactionSource;
}

export type SetSelectedUniqueOfferPayload = Dispatch<
  SelectedUniqueOfferPayload | undefined
>;

export interface AddUniqueOfferFormModel
  extends Omit<
    SelectedUniqueOfferPayload,
    'supplierFields' | 'cardActivationLimit'
  > {
  supplierInfo: SelectedUniqueOfferPayload['supplierFields'];
  qualifiedTransactionsLimit: SelectedUniqueOfferPayload['cardActivationLimit'];
}

export type CreateUpdateOfferFormModel =
  | PublisherOfferFormModel
  | ContentProviderOfferFormModel;

export interface PublisherOfferFormModel
  extends CreateUpdateOfferKeyValueFields {
  activation: OfferCardActivation['enabled'];
  qualifiedTransactionsLimit?: OfferQualifiedTransactionsLimit;
  schemes?: string[];
  brandId?: string;
  offerName: string;
  country: CountryCode;
  discountAmount: string;
  discountType: 'percentage' | 'exact';
  minSpend: string;
  maxReward?: string;
  schedule: WeekDay[];
  returnDays: string;
  startDate?: any;
  startTime?: any;
  endDate?: any;
  endTime?: any;
  terms: string;
  selfFunded: boolean;
  supplier?: string;
  programsToLinkLocationsFrom?: string[];
  programId?: string;
  feeSplit?: number;
  customiseFees: boolean;
  publisherFee?: number;
  fidelFee?: number;
  mids?: NetworkIdentifierWithScheme[];
  maxRedemptions: boolean;
  transactionsLimit?: number;
  period?: number;
  unit?: 'month' | 'day' | 'year';
}

export interface ContentProviderOfferFormModel
  extends Omit<PublisherOfferFormModel, 'discountAmount'> {
  approval: boolean;
  channel?: string;
  grossCommission: number;
  longDescription?: string;
  maxPaymentTimeline: number;
  customerRecencyEnabled: boolean;
  customerRecencyDays: number;
  shortDescription?: string;
  customiseFees: boolean;
  publisherFee?: number;
  fidelFee?: number;
  mids?: NetworkIdentifierWithScheme[];
}

export type CreateUpdateOfferKeyValueFields = {
  [K in 'supplierInfo' | 'metadata']: { key: string; value: string }[];
};

export type SupportedTitleProps =
  | 'brandLogoURL'
  | 'brandName'
  | 'created'
  | 'name'
  | 'requiresApproval'
  | 'activation';

export type OfferTitleDetails = Partial<Pick<Offer, SupportedTitleProps>>;

export type OffersUserRole =
  | 'content-provider'
  | 'marketplace-publisher'
  | 'publisher';

export type OfferProviderType = {
  name: string;
  identifier: string;
  id: string;
  requiredFields: string[] | null;
  profileRequiredFields?: string[];
};

type VisaIdentifiers =
  | {
      vmid: string;
      vsid: string;
    }
  | {
      caid: string;
      bin: string;
    };
type AmexIdentifiers = { seNumber: string };
type MastercardIdentifiers = {
  merchantId: string;
  locationId: string;
};

export type NetworkIdentifiers =
  | VisaIdentifiers
  | AmexIdentifiers
  | MastercardIdentifiers;

export type OaasMid = {
  id: string;
  merchantId: string;
  created: string;
  updated: string;
  scheme?: string;
  locationId?: string;
  networkIdentifiers?: NetworkIdentifiers;
};

export type NetworkIdentifierWithScheme = {
  scheme: string;
  networkIdentifiers: NetworkIdentifiers;
};
